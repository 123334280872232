@use 'colors' as colors;

.footer-container {
  position: relative;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .icons-container {
    width: 40%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 50px;
    padding-top: 60px;
    margin-top: 150px;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 50px;

    button {
      border-radius: 16px;
      font-size: 16px;
      letter-spacing: 2px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 12px 32px;
      cursor: pointer;
      width: 300px;
    }
    button {
      background-color: transparent;
      border: 1px solid colors.$navbar-text-color;

      a, a:visited {
        color: colors.$navbar-text-color;
      }
    }

    button:hover {
      background-color: white;
      border: 1px solid white;
      a, a:visited {
        color: black;
      }
    }
  }

  footer {
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 12px;

    p {
      padding: 32px;
      font-size: 16px;
      letter-spacing: 4px;
      font-weight: lighter;
      word-spacing: 6px;
      color: white;
    }
  }
}

@media only screen and (max-width: 872px) {
  .footer-container {
    min-height: 200px;
    .icons-container {
      gap: 25px;
      margin-top: 42px;
    }
    .buttons-container {
      flex-direction: column;
    }
  }
}