@use 'colors' as colors;

$body-max-width: 1920px;
$menu-link-border: 1px solid colors.$content-menu-link-border-color;
$menu-link-box-shadow:
  0 0 8px 3px colors.$content-menu-link-border-color,
  inset 0 0 10px colors.$content-menu-link-border-color;

$menu-link-text-shadow:
  0px 0px 8px colors.$content-menu-link-text-shadow,
  0px 0px 8px colors.$content-menu-link-text-shadow,
  0px 0px 8px colors.$content-menu-link-text-shadow,
  0px 0px 8px colors.$content-menu-link-text-shadow;

$new-icon-box-shadow:
0 0 8px 3px red,
inset 0 0 10px red;

$table-first-row-background: linear-gradient(to bottom right, colors.$table-first-row-background-1, colors.$table-first-row-background-2);
$table-row-background: linear-gradient(to bottom right, colors.$table-row-background-1, colors.$table-row-background-2);