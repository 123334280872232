@use 'variables' as variables;
@use 'colors' as colors;

.loading-banner {
  height: 100vh;
  min-height: 700px;
  min-width: 350px;
  max-height: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.banner {
  height: 100vh;
  min-height: 830px;
  max-height: 900px;
  min-width: 350px;
  .parallax-banner {
    height: 100%;
    position: relative;
  }

  .new-tournament-logo {
    width: 100%;
    height: 100%;
    margin: auto;
    background-size: calc(min(626px, 100%)) auto !important;
    background-position: top center !important;
    background-repeat: no-repeat;
    position: relative;
  }

  .logo-text {
    position: absolute;
    bottom: -144px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 16px;
    height: 38em;
    width: 100%;
    padding: 0px 16px;

    section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin: 0px auto;
      width: 100%;
    }

    .total-prizes-container {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px){
        height: 1em;
        flex-direction: row;
        justify-content: center;
      }
    }
    .total-prize-1 {
      font-size: 36px;
      line-height: 1em;
      height: -10em;
      margin-top: 6em;

      @media screen and (min-width: 768px) {
        margin-top: 7.1em;
      }
    }

    .total-prize-2 {
      color: #FFD285;
      font-size: 46px;
      line-height: 1em;
      height: -20em;
    
      @media screen and (min-width: 768px) {
        margin-top: 4.5em;
        padding: 1em;
      }
    }

    .event-time {
      color: #52B8FF;
      line-height: 1.8em;

      @media screen and (min-width: 768px) {
        margin-top: -4em;
      }
    }

    .private-table {
      font-size: 28px;
      line-height: 1.4em;
      margin-top: -10px;
    }
  }

  .cn-logo-text {
    bottom: -148px;
  }

  .poker-heart, .poker-diamond, .poker-clubs, .poker-spade {
    width: 100%;
    height: 100%;
    background-size: 40% 40% !important;
    background-repeat: no-repeat;
  }

  .poker-heart {
    background-position: left -150px top 200px !important;
  }

  .poker-diamond {
    background-position: right -150px top 200px !important;
  }

  .poker-clubs {
    background-position: left -55px bottom 200px !important;
  }

  .poker-spade {
    background-position: right -75px top 350px !important;
  }

  .coin-1, .coin-2, .coin-3, .coin-4, .coin-5, .coin-6 {
    width: 100%;
    height: 100%;
    background-size: 50px 50px !important;
    background-repeat: no-repeat;
  }

  .coin-1 {
    background-position: left 20% top 20% !important;
  }

  .coin-2 {
    background-size: 100px 100px !important;
    background-position: right 20% top 30% !important;
  }

  .coin-3 {
    background-position: top 10% right 20% !important;
  }

  .coin-4 {
    background-size: 100px 100px !important;
    background-position: top 20% left 0% !important;
  }

  .coin-5 {
    background-position: top 20% right !important;
  }

  .coin-6 {
    background-size: 100px 100px !important;
    background-position: top 40% left 15% !important;
  }

  .cover-1 {
    width: 100%;
    height: 100%;
    background-size: 100% 100% !important;
    background-position: top center !important;
    animation: fadeInAnimation ease 1s;
  }

  .cover-1-mobile {
    width: 100%;
    height: 100%;
    background-size: 120% !important;
    background-repeat: no-repeat;
    background-position: top center !important;
    animation: fadeInAnimation ease 1s;
  }

  .lantern {
    width: 100%;
    background-size: contain !important;
    background-repeat: no-repeat;
    margin-top: -13%;
  }

  .circle {
    width: 100%;
    background-size: 100% !important;
    background-repeat: no-repeat;
    background-position-y: 93px !important;
  }

  .fans {
    width: 100%;
    background-size: 100% !important;
    background-repeat: no-repeat;
    background-position-y: 87% !important;
  }

  .wave1, .wave2, .wave3, .wave4, .wave5 {
    width: 100%;
    background-size: 100% !important;
    background-repeat: no-repeat;
    background-position-y: bottom !important;
  }

  .cloud {
    background-repeat: no-repeat;
    background-size: min(200px, 12%) !important;
  }

  .blue-cloud-left-1 {
    background-position:  left 18% bottom 30% !important;
  }

  .blue-cloud-left-2 {
    background-position:  left 7% bottom 15% !important;
  }

  .red-cloud-right-2 {
    background-position:  right 7% bottom 15% !important;
  }

  .red-cloud-left-1 {
    background-position:  left 2% bottom 40% !important;
  }

  .red-cloud-right-1 {
    background-position:  right 3% bottom 42% !important;
  }

  .blue-cloud-right-1 {
    background-position:  right 12% bottom 30% !important;
  }

  .stairs {
    width: 100%;
    margin: auto;
    background-size: 100% !important;
    background-repeat: no-repeat;
    background-position-y: 100% !important;
  }

  .confetti {
    background-size: 100% !important;
    background-repeat: no-repeat;
  }

  .logo-container {
    position: relative;
  }

  .mt-logo {
    width: 400px;
    height: 320px;
    background-size: contain !important;
    background-repeat: no-repeat;
    margin: 80px auto;
    position: relative;
    animation: floating-logo 4s ease-in-out infinite;
  }

  .dragon-eye {
    position: absolute;
    top: 97px;
    left: 196px;
  }

  .prizes-container{
    height:25em;

    @media screen and (min-width: 768px) {
      margin-top: 10em;
    }
  }

  .mt-text-slogan {
    max-width: 1536px;
    max-height: 820px;
    min-height: 700px;
    width: 100vw;
    height: 90vh;
    background-size: contain !important;
    background-repeat: no-repeat;
    margin: 64px auto;
    margin-bottom: 0px;

    img {
      display: block;
      max-width: 1075px;
      width: 90%;
      height: auto;
      margin: 64px auto -80px auto;
    }
  }

  .title-logo {
    width: 100%;
    margin: auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 24px;
    white-space: break-spaces;
    color: colors.$title-logo-date-text-color;
    -webkit-text-stroke: 1px colors.$title-logo-date-stroke;

    p {
      font-size: 24px;
    }
  }

  .ranking-board-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: min(31%, 286px) auto 0px auto;
    width: 92vw;
    max-width: 1200px;

    .congrats-text {
      background: -webkit-linear-gradient(#ffffff, colors.$ranking-board-congrats-text-color-2);
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 8px 0px;
      margin-bottom: 16px;
      max-width: 350px;
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

@keyframes floating-logo {
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(16px); }
}

@keyframes fadeInAnimation {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media only screen and (max-width: 768px) {
  .logo-text {
    bottom: -60px;
    section {
      flex-direction: column;
    }
  }
}
