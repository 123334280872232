@use 'colors' as colors;

.App {
  margin: 0;
  background: url('../assets/newTournament/bg_s\ 1.png');
  background-size: contain;
  background-repeat: repeat-y;

  header {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
  }


  section {
    h2 {
      background: -webkit-linear-gradient(#FFE0A5, #CA981F);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 1px 1px 0 1 black;
      text-align: center;
      line-height: 1.5em;
      font-size: 32px;
      margin-top: 64px;
      text-transform: uppercase;
    }
  }

  .mobile-background {
    background: url('../assets/newTournament/bg_s\ 1.png');
    background-repeat: repeat-y;
    background-size: 100%;
  }

}

@media only screen and (max-width: 768px) {
  .App {
    section {
      h2 {
        font-size: 2em;
      }
    }
  }
  
}
