$background-color-top: #04091c;
$background-color-middle: #050e31;
$background-color-bottom: #081c4c;
$background-gradient-main: linear-gradient(0deg, #07246E ,#040A26, #040919 );

$title-logo-date-stroke: #281105;
$title-logo-date-text-color: #FFF5D9;

$navbar-background-color2: #1b1d20;
$navbar-anchor-highlight: #FFD566;
$navbar-anchor-text-border: #D29E00;
$navbar-text-color: #c7c6c6;

$preheader-background-color: #08617c;
$subheader-background-color: #ff6600;
$subheader-bottom-border-color: #0ea1ce;
$preheader-text-color: #76e0ff;
$preheader-divider-color: #064a5f;

$menu-list-email-top: #8cc8d9;
$content-menu-bg: #10142c;
$content-menu-border-top-left: #30ffff;
$content-menu-border-top-right: #6e00a1;
$content-menu-link-border-color: #5e8aa0;
$content-menu-link-text-shadow: #5fcaeb;

$content-menu-divider-start: #12394f;
$content-menu-divider-middle: #2cddfb;
$content-menu-divider-end: #2cddfb;

$introduction-card-header: #8b6b38;
$introduction-card-divider: #c4ac7d;
$introduction-step-text-color: #e9c99c;
$introduction-card-text-color: #d2e9ff;

$ranking-board-congrats-text-color-2: #FADB9F;
$ranking-board-congrats-text-border: #513A21;
$ranking-board-top-text-color: #E1C336;
$ranking-board-sub-text-color: #fbd16d;
$ranking-board-badge-border-text: #826117;
$ranking-board-disabled-text-color: #723A3A;
$ranking-board-enabled-text-color: #FFF5D9;

$table-first-row-background-1: rgb(20, 60, 140, 0.8);
$table-first-row-background-2: rgb(1, 18, 52, 0.8);
$table-row-background-1: rgb(31, 73, 136, 0.8);
$table-row-background-2: rgb(2, 26, 76, 0.8);