
@use 'colors' as colors;
@use 'variables' as variables;

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  scroll-padding-top: 160px;
}

body {
  margin: 0px auto;
  max-width: variables.$body-max-width;
  padding: 0;
  scroll-behavior: smooth;
  font-family: 'Arial', 'sans-serif';
  line-height: 1.2em;
  color: white;
  background: colors.$background-color-top;
  box-shadow: 0px 0px 15px grey;
}

p {
  font-size: 20px;
  line-height: 2em;
}

